import React from 'react';
import Header from '../components/HostHeader';
import BannerSection from '../components/HostBannerSection';
import DashboardSection from '../components/DashboardSection';
import NewsletterSection from '../components/HostNewsletterSection'
import FooterSection from '../components/FooterSection'
import SuccessNotification from '../components/SuccessNotification'
import PrivacyPolicyModal from '../components/PrivacyPolicyModal'
import TOSModal from '../components/TOSModal';
import YourTicketsYourRulesSection from '../components/YourTicketsYourRulesSection';
import EmbeddableSection from '../components/EmbeddableSection';
import CustomisableSection from '../components/CustomisableSection';

function Host() {
  return (
    <div className="bg-neutral-900">
      <Header />
      <BannerSection />
      <DashboardSection />
      <YourTicketsYourRulesSection />
      <EmbeddableSection />
      <CustomisableSection />

      <NewsletterSection />
      <FooterSection />
      <SuccessNotification />
      <PrivacyPolicyModal />
      <TOSModal />
    </div>
  );
}

export default Host;