import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Landing from './pages/Landing'
import Host from './pages/Host'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="fans" element={<Landing />} />
          <Route index element={<Host />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
