import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useRef, useState, useEffect } from 'react'
import { useIsVisible } from '../../hooks';

const tiers = [
  {
    name: 'Initial Sales',
    id: 'intial-sales',
    href: '#',
    description: 'Manage how fans buy tickets to your events',
    features: [
      ['Set ticket tiers and prices', 'check'],
      ['Manage availability', 'check'],
      ['Offer faster, easier checkouts', 'check'],
    ]
  },
  {
    name: 'Resales',
    id: 'resell-host',
    href: '#',
    description: 'Tap into the lucrative ticket resale market',
    features: [
      ['Collect royalties on resale ticket revenues', 'check'],
      ['Set optional price limits for resales to combat scalpers', 'check'],
    ],
  },
  {
    name: 'Ongoing',
    id: 'ongoing-host',
    href: '#',
    description: 'Stay connected to your fans after the event',
    features: [
      ['Gain insights into evolving fan preferences', 'check'],
      ['Send digital gifts and promotions', 'check'],
    ],
  }
]

export default function YourTicketsYourRulesSection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)

  return (
    <div className="bg-neutral-900 py-32 -mt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div ref={title} className={`mx-auto max-w-4xl sm:text-center transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl"> 
            <a className="text-white">
              Your tickets, 
            </a>
            <a className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-purple-500">
              &nbsp;your rules
            </a>
          </p>
        </div>
        <div className={`transition-opacity ease-in-out duration-500 delay-150 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <p className="mx-auto mt-6 max-w-3xl text-lg leading-8 text-white sm:text-center">
          A ticket's journey can be long and complex, but with revolutionary smart tickets you have unmatched control over that journey, including:
          </p>
        </div>
        
        <div className={`my-20 flex justify-center transition-opacity ease-in-out duration-500 delay-300 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <div className="justify-center isolate -mt-16 grid max-w-xs grid-cols-1 gap-y-16 divide-y divide-gray-600 sm:mx-auto lg:-mx-0 lg:mt-0 lg:max-w-5xl lg:grid-cols-3 lg:divide-x lg:divide-y-0">
            {tiers.map((tier) => (
              <div key={tier.id} className="justify-center pt-16 lg:px-8 lg:pt-0 xl:px-14">
                {/* <h3 id={tier.id} className="text-center bg-royalpurple-500 text-base font-semibold leading-9 text-white">
                  {tier.name}
                </h3> */}
                <h3 id={tier.id} className="text-2xl sm:text-3xl font-bold leading-8 text-white">
                  {tier.name}
                </h3>
                <p className="justify-left mt-6 flex items-baseline gap-x-1">
                  <span className="text-left text-sm font-normal leading-6 text-white">{tier.description}</span>
                </p>
                <ul role="list" className="mt-6 space-y-3 text-sm font-light leading-6 text-white">
                  {tier.features.map((feature) => (
                      <li key={feature[0]} className="flex gap-x-2">
                        {feature[1] === 'check' ? <CheckIcon className="h-6 w-5 flex-none text-green-400" aria-hidden="true" />
                        : <XMarkIcon className="h-6 w-5 flex-none text-neutral-500" aria-hidden="true" />}
                        {feature[0]}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
