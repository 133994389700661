import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useRef, useState, useEffect } from 'react'
import { useIsVisible } from '../../hooks';

//TODO: Change this section to match the Figma content, while using this as a template to start from
export default function EmbeddableSection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)

  return (
    <div className="slanted-rect-xs sm:slanted-rect bg-neutral-800 py-32 -my-36 sm:py-64">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
        <div className='my-auto'>
          <div ref={title} className={`max-w-xl transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <h2 className="text-base font-semibold leading-7 text-hotpink-700">FULLY EMBEDDABLE</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl"> 
              <a className="text-white">
                Sell on your site,
              </a> <br/>
              <a className="text-transparent bg-clip-text bg-gradient-to-r from-hotpink-700 to-purple-500">
                no coding skills needed
              </a>
            </p>
          </div>
          <div className={`max-w-xl transition-opacity ease-in-out duration-500 delay-150 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <p className="mt-6 text-lg leading-8 text-white">
              No need to send fans to another platform: quickly set up checkout on your own website, or use a dedicated page on ours. Works with Squarespace, Wix, and more.
            </p>
          </div>
        </div>
        
        <div className={`mt-32 lg:mt-0 flex justify-center transition-opacity ease-in-out duration-500 delay-300 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <img
              src="embeddable.png"
              alt="Fully Embeddable"
              className={`z-10 w-[285px] h-[362px] xl:w-[400px] xl:h-[508px] aspect-auto`}
            />
          <div className='-mt-12 xl:-mt-24 -mr-48 xl:-mr-96 absolute dotted-bg w-[150px] h-[150px] xl:w-[250px] xl:h-[250px]'/>
          <div className='mt-64 xl:mt-80 -ml-64 xl:-ml-72 absolute bg-neutral-900 w-[150px] h-[150px] xl:w-[250px] xl:h-[250px] rounded-full'/>
        </div>
      </div>
    </div>
  )
}
