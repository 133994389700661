import React, {useState} from 'react'
import axios from 'axios'
import { usePrivacyPolicyOpenManager, useTOSOpenManager, useWaitlistSuccessManager } from '../../contexts/Application'
import { NEWSLETTER_URL } from '../../constants';
import { validateEmail } from '../../utils'

export default function NewsletterSection() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [, updateWaitlistSuccess] = useWaitlistSuccessManager()
  const [, updatePrivacyPolicyOpen] = usePrivacyPolicyOpenManager()
  const [, updateTOSOpen] = useTOSOpenManager()
  const [isSending, setIsSending] = useState(false);

  return (
    <div className="bg-neutral-900 z-50">
      <div className="lg:-mt-20 mb-40 lg:mb-20 relative bg-emerald-700 mx-auto lg:max-w-6xl px-6 lg:px-8 lg:z-10 lg:rounded-xl">
        <div className="md:flex items-center gap-10 mx-12 py-12 justify-between">
          <div className="min-w-full md:min-w-0 text-left">
            <h3 className="text-3xl font-extrabold tracking-tight text-white">
              You’re excited? So are we!
            </h3>
            <p className="mt-6 text-lg leading-8 tracking-tight text-neutral-300">
              Sign up for our newsletter to receive the latest news about our product development journey, and be the first to hear about it when we launch!
            </p>
          </div>
          <div className="mt-4 md:mt-1 flex items-center justify-left gap-x-6">
            <form className="w-full max-w-md lg:col-span-5 lg:pt-2" method="POST" action={NEWSLETTER_URL}
                id="mc-embedded-subscribe-form-bottom" name="mc-embedded-subscribe-form-bottom"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!validateEmail(email)) {
                    setError(true);
                  } else {
                    var bodyFormData = new FormData();
                    bodyFormData.append('EMAIL', email);
                    // bodyFormData.append('OPT_IN', '1');
                    setIsSending(true)
                    axios.post(
                      NEWSLETTER_URL, 
                      bodyFormData, 
                      {
                        headers: { 'content-type': undefined }
                      }
                    ).catch(() => {
                      // catch any CORS errors
                      return
                    }).finally(() => {
                      setIsSending(false)
                      updateWaitlistSuccess(true)
                    })
                  }
                }}>
              <div className="sm:flex gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="min-w-full sm:min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                  onChange={(event) => {
                    setError(false); 
                    updateWaitlistSuccess(false);
                    setEmail(event.target.value);
                  }}
                />
                <button
                  type="submit"
                  disabled={isSending}
                  className="mt-2 min-w-full sm:min-w-0 sm:mt-0 flex-none rounded-md bg-hotpink-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500  disabled:cursor-not-allowed disabled:opacity-75"
                >
                  Join waitlist
                </button>
              </div>
              <p className="mt-4 text-sm leading-6 text-neutral-300">
                We&apos;ll protect your data. Read our{' '}
                <a href="#" className="underline text-white;" onClick={(e) => {
                e.preventDefault()
                updatePrivacyPolicyOpen(true)
                }}>
                Privacy&nbsp;Policy
                </a>
                &nbsp;and{' '}
                <a href="#" className="underline text-white;" onClick={(e) => {
                e.preventDefault()
                updateTOSOpen(true)
                }}>
                Terms&nbsp;of&nbsp;Service
                </a>
                .
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}