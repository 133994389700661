import React, {useState} from 'react'
import axios from 'axios'
import { usePrivacyPolicyOpenManager, useTOSOpenManager, useWaitlistSuccessManager } from '../../contexts/Application'
import { NEWSLETTER_URL } from '../../constants'
import { validateEmail } from '../../utils'
import ReactPlayer from 'react-player'

export default function HostBannerSection() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [, updateWaitlistSuccess] = useWaitlistSuccessManager()
  const [, updatePrivacyPolicyOpen] = usePrivacyPolicyOpenManager()
  const [, updateTOSOpen] = useTOSOpenManager()
  const [isSending, setIsSending] = useState(false);

  return (
    <div className="bg-neutral-900">
      <div className="relative isolate overflow-hidden pt-14 lg:pb-32">
        <div className="absolute inset-0 -z-10 h-full w-full object-cover">
          
          <div className='absolute z-10 min-h-full min-w-full'>
            <div className='absolute min-h-full min-w-full bg-neutral-900 opacity-75'/>
            <div className='absolute min-h-full min-w-full bg-gradient-to-t from-neutral-900 to-30%' />
          </div>
          <ReactPlayer 
            className={"absolute react-player"}
            url={"https://metapass-next.s3.us-west-1.amazonaws.com/HostPageBannerVideo.mp4"} 
            playing
            loop
            muted
            height={"100%"}
            width={"100%"}
          />
        </div>
        {/* <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
        <div className="mx-auto max-w-6xl px-8 py-32 sm:py-48 lg:pt-32 lg:pb-80">
            <div className="text-left max-w-2xl">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Supercharge your fans with smart tickets
            </h1>
            <p className="mt-6 text-lg leading-8 tracking-tight text-white max-w-2xl">
                With faster checkouts, protection from scalpers, and unmatched flexibility,<br/>
                Metapass smart tickets make it easier than ever to grow your fandom.
            </p>
            <p className="mt-8 text-lg leading-8 tracking-tight text-neutral-200">
                {/*Learn more about our upcoming pilot:*/}
            </p>
            <div className="mt-1 flex items-center justify-left gap-x-6">
                <form className="w-full max-w-md lg:col-span-5 lg:pt-2" method="POST" action={NEWSLETTER_URL}
                id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!validateEmail(email)) {
                    setError(true);
                    } else {
                    var bodyFormData = new FormData();
                    bodyFormData.append('EMAIL', email);
                    // bodyFormData.append('OPT_IN', '1');
                    setIsSending(true)
                    axios.post(
                        NEWSLETTER_URL, 
                        bodyFormData, 
                        {
                        headers: { 'content-type': undefined }
                        }
                    ).catch(() => {
                        // catch any CORS errors
                        return
                    }).finally(() => {
                        setIsSending(false)
                        updateWaitlistSuccess(true)
                    })
                    }
                }}>
                <div className="sm:flex gap-x-4">
                    <label htmlFor="email-address" className="sr-only">
                    Email address
                    </label>
                    <input
                    id="EMAIL" 
                    name="EMAIL"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-full sm:min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    placeholder="Email"
                    onChange={(event) => {
                        setError(false); 
                        updateWaitlistSuccess(false);
                        setEmail(event.target.value);
                    }}
                    />
                    <button
                    type="submit"
                    className="mt-2 min-w-full sm:min-w-0 sm:mt-0 flex-none rounded-md bg-hotpink-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:opacity-75"
                    >
                    Join waitlist
                    </button>
                </div>
                <p className="mt-4 text-xs leading-6 text-neutral-400">
                    Read our{' '}
                    <a href="#" className="underline text-white;" onClick={(e) => {
                    e.preventDefault()
                    updatePrivacyPolicyOpen(true)
                    }}>
                    Privacy&nbsp;Policy
                    </a>
                    &nbsp;and{' '}
                    <a href="#" className="underline text-white;" onClick={(e) => {
                    e.preventDefault()
                    updateTOSOpen(true)
                    }}>
                    Terms
                    </a>
                    .
                </p>
                </form>
            </div>
            </div>
        </div>
        {/* <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
      </div>
    </div>
  )
}
