import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

// const navigation = [
//   { name: 'Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Company', href: '#' },
// ]

function HostHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-transparent absolute inset-x-0 top-0 z-40">
      <nav className="mx-auto flex max-w-6xl items-center justify-between p-6 lg:p-4 lg:px-8" aria-label="Global">
        <div className="flex flex-1 hidden sm:block">
          {/* <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item: any) => (
              <a key={item.name} href={item.href} className="text-sm font-medium leading-6 text-neutral-50">
                {item.name}
              </a>
            ))}
          </div> */}
          {/* <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-neutral-50"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div> */}
        </div>
        <a href="/" className="-m-1.5 p-1.5 sm:block hidden">
          <span className="sr-only">Metapass LLC</span>
          <img className="h-5 w-auto" src="metapass_full_white.svg" alt="" />
        </a>
        <a href="/" className="-m-1.5 p-1.5 block sm:hidden">
          <span className="sr-only">Metapass LLC</span>
          <img className="h-7 w-auto" src="metapass_min_white.svg" alt="" />
        </a>
        <div className="flex flex-1 justify-end">
          <a href="/fans" className="text-sm group font-medium leading-6 text-neutral-50">
            For Fans <span aria-hidden="true" className='inline-block transition transform group-hover:translate-x-1'>&rarr;</span>
          </a>
        </div>
      </nav>
      {/* <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-neutral-800 px-6 py-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-1">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-neutral-50"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Metapass LLC</span>
              <img
                className="h-8 w-auto"
                src="metapass_min_white.svg"
                alt=""
              />
            </a>
            <div className="flex flex-1 justify-end">
              <a href="#" className="text-sm font-medium leading-6 text-neutral-50">
              Artists & Hosts <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="mt-6 space-y-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-neutral-50 hover:bg-neutral-70"
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog> */}
    </header>
  )
}

export default HostHeader