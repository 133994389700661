import { useRef, useState, useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useIsVisible } from '../../hooks';


const rewards = [
  {
    name: 'Fan Loyalty Rewards',
    id: 'rewards',
    href: '#',
    description: 'Earn points not just whenever you attend a live event, but soon with every merchandise purchase you make with an authorised outlet, and even with every track you play on partnered streaming platforms.\n\nWork your way up the tiers, and enjoy unique yet powerful perks, such as (for example):',
    features: [
      'Priority access to early bird ticket sales',
      'Guaranteed tickets for upcoming event tours',
      'Meet and greet session passes',
      'Exclusive discounts on event merchandise and F&B outlets',
    ]
  },
  {
    name: 'Take control of your experience',
    id: 'control',
    href: '#',
    description: 'Selected loyalty tiers may have the opportunity to influence concert experiences, such as voting on event venue design, artist wardrobes, and possibly even track lists!',
    features: []
  },
]

export default function LoyaltySection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)


  return (
    <div className="overflow-hidden bg-neutral-900 py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col items-end text-right">
          <div ref={title} className={`transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <h2 className="uppercase text-base font-semibold leading-6 text-red-500">Loyalty Rewards</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">
              <a className='text-white'>Here's one for the</a>
              <a className="text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-orange-500">&nbsp;diehards</a>
            </p>
          </div>
          <div className={`transition-opacity ease-in-out duration-500 delay-150 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <p className="mt-6 text-right font-normal text-lg leading-7 text-neutral-200">
                We’re making it even more rewarding for our most loyal fans, with unique <br/>
                perks and experiences up for grabs. Keep rocking on, diehards!
            </p>
          </div>
        </div>
        <div className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className={`lg:max-w-lg transition-opacity ease-in-out duration-500 delay-300 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
              <dl className="mt-10 max-w-xl space-y-10 text-base leading-7 text-neutral-600 lg:max-w-none">
                {rewards.map((section) => (
                  <div key={section.id} className="relative pl-9">
                    <h3 id={section.id} className="text-2xl font-bold leading-9 text-white">
                      {section.name}
                    </h3>
                    <p className="mt-3 flex items-baseline gap-x-1">
                      <span className="text-base leading-7 font-normal text-neutral-50">{section.description}</span>
                    </p>
                    <ul role="list" className="mt-6 mb-6 space-y-3 text-sm font-light leading-6 text-white">
                      {section.features.map((feature) => (
                        <li key={feature} className="flex gap-x-2">
                          <CheckIcon className="h-6 w-5 flex-none text-green-400" aria-hidden="true" />
                          <a className='text-base text-neutral-50'>{feature}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className={`flex justify-center lg:justify-normal lg:my-0 transition-opacity ease-in-out duration-500 delay-450 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <img
              src="loyalty_mobile.png"
              alt="Loyalty Page"
              className="mr-12 lg:mr-16 w-72 lg:w-[270px] lg:h-[560px] aspect-auto max-w-none"
            />
              <img
              src="interactions_mobile.png"
              alt="Interactions Page"
              className="w-72 lg:w-[270px] lg:h-[560px] aspect-auto max-w-none"
            />

          </div>
        </div>
      </div>
    </div>
  )
}
