import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useRef, useState, useEffect } from 'react'
import { useIsVisible } from '../../hooks';

const tiers = [
  {
    name: 'Refund',
    id: 'refund',
    href: '#',
    description: 'Refund your ticket directly back to Metapass',
    features: [
      ['Guaranteed money back within 3-5 days', 'check'],
      ['Release tickets to other fans in your community', 'check'],
      ['Multiple payment methods supported', 'check'],
      ['All at no additional cost', 'check'],
    ]
  },
  {
    name: 'Resell',
    id: 'resell',
    href: '#',
    description: 'Resell your ticket on our official secondary marketplace',
    features: [
      ['Set your own price, subject to any restrictions set by event host', 'check'],
      ['One click listing, seamless transactions', 'check'],
      ['Payouts will be processed within 3-5 working days via bank transfer', 'check'],
      ['Tickets listed for resell will void refund guarantee', 'cross'],
    ],
  }
]

//TODO: Change this section to match the Figma content, while using this as a template to start from
export default function RefundSection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)

  return (
    <div className="slanted-rect-xs sm:slanted-rect bg-neutral-800 py-32 -my-36 sm:py-48">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div ref={title} className={`mx-auto max-w-4xl sm:text-center transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <h2 className="text-base font-semibold leading-7 text-cyan-400">REFUNDS & RESALE</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl"> 
            <a className="text-white">
              We get it, 
            </a>
            <a className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
              &nbsp;plans change
            </a>
          </p>
        </div>
        <div className={`transition-opacity ease-in-out duration-500 delay-150 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-white sm:text-center">
          That&apos;s why we are making it easier than ever to get the money back for 
  your tickets, and pass on the opportunity for other fans to attend.
          </p>
        </div>
        
        <div className={`mt-20 flex justify-center transition-opacity ease-in-out duration-500 delay-300 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <div className="justify-center isolate -mt-16 grid max-w-xs grid-cols-1 gap-y-16 divide-y divide-gray-600 sm:mx-auto lg:-mx-0 lg:mt-0 lg:max-w-4xl lg:grid-cols-2 lg:divide-x lg:divide-y-0">
            {tiers.map((tier) => (
              <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id={tier.id} className="text-2xl sm:text-3xl font-bold leading-8 text-white">
                  {tier.name}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-base font-normal leading-6 text-white">{tier.description}</span>
                </p>
                <ul role="list" className="mt-6 space-y-3 text-sm font-light leading-6 text-white">
                  {tier.features.map((feature) => (
                      <li key={feature[0]} className="flex gap-x-2">
                        {feature[1] === 'check' ? <CheckIcon className="h-6 w-5 flex-none text-green-400" aria-hidden="true" />
                        : <XMarkIcon className="h-6 w-5 flex-none text-neutral-500" aria-hidden="true" />}
                        {feature[0]}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
