import { useRef, useState, useEffect } from 'react'
import { LockClosedIcon, BoltIcon, CreditCardIcon } from '@heroicons/react/24/outline'
import { useIsVisible } from '../../hooks';

const features = [
  {
    name: 'Verifiable and traceable ticketing',
    description:
      'Say goodbye to duplicate and fraudulent tickets. Every one of our smart tickets can be easily verified via a simple QR code before your purchase, so you can always enjoy peace of mind.',
    icon: LockClosedIcon,
  },
  {
    name: 'Transfers are seamless and instant',
    description: 'Whether it is transferring to your friends, or after a secondary resale, all our tickets transfers are completed within seconds.',
    icon: BoltIcon,
  },
  {
    name: 'Secure payment channels',
    description: 'We support all major payment methods, including Visa, Mastercard, Apple Pay and more.',
    icon: CreditCardIcon,
  },
]

export default function SmartTicketSection() {

  const imageTop = useRef<HTMLImageElement>(null);
  const isImageTopVisible = useIsVisible(imageTop);

  const imageBottom = useRef<HTMLImageElement>(null);
  const isImageBottomVisible = useIsVisible(imageBottom);

  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  const [triggerTop, setTriggerTop] = useState(false)
  const [triggerButtom, setTriggerBottom] = useState(false)
  const [triggerTitle, setTriggerTitle] = useState(false)

  useEffect(() => {
    if (isImageTopVisible && !triggerTop) {
      setTriggerTop(true)
    }
  }, [isImageTopVisible])

  useEffect(() => {
    if (isImageBottomVisible && !triggerButtom) {
      setTriggerBottom(true)
    }
  }, [isImageBottomVisible])

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])
  


  return (
    <div className="overflow-hidden py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <div ref={title} className={`transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
                <h2 className={`text-base font-semibold leading-7 text-yellow-500`}>SMART TICKETS</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-5xl">Safe and secure,<br/></p>
                <p className="mt-2 text-3xl font-extrabold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-green-400 sm:text-5xl">like how it should be</p>
              </div>
              <div className={`transition-opacity ease-in-out delay-150 duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
                <p className="mt-6 text-lg leading-8 text-white">
                Our state-of-the-art smart ticketing infrastructure is designed to 
                eliminate some of the most common security woes of the industry.
                </p>
              </div>
              <div className={`transition-opacity ease-in-out delay-300 duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-neutral-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="flex justify-between">
                      <div className="mr-6 h-10 w-10 bg-orange-400 rounded-md flex items-center justify-center shrink-0">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <div>
                        <dt className="inline font-semibold text-white">
                          {feature.name}
                        </dt>{' '}
                        <br/>
                        <dd className="inline font-light text-white">{feature.description}</dd>
                      </div>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
          <div className='flex justify-center lg:justify-normal'>
            <img
              ref={imageTop}
              src="tickets_mobile.png"
              alt="Ticket Code Page"
              className={`w-72 mr-12 lg:-mt-48 lg:w-[350px] lg:mr-16 lg:h-[725px] aspect-auto max-w-none transition ease-in-out duration-700 delay-600 ${triggerTop ? "lg:-translate-y-0" : "lg:-translate-y-60"} ${triggerTop ? "opacity-100" : "opacity-0"}`}
            />
            <img
              ref={imageBottom}
              src="payments_mobile.png"
              alt="Payments Page"
              className={`w-72 lg:-mb-96 lg:mt-24 lg:w-[350px] lg:h-[725px] aspect-auto max-w-none transition ease-in-out duration-700 delay-450 ${triggerTop ? "lg:-translate-y-0" : "lg:translate-y-60"} ${triggerTop ? "opacity-100" : "opacity-0"}`}
            />

          </div>
        </div>
      </div>
    </div>
  )
}
