import { useRef, useState, useEffect } from 'react'
import { SparklesIcon } from '@heroicons/react/24/solid'
import { useIsVisible } from '../../hooks';

export default function FanSection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)


  return (
    <div className="bg-neutral-900 z-50">
      <div className="lg:-mt-64 relative bg-neutral-800 py-12 mx-auto lg:max-w-4xl px-6 lg:px-8 lg:z-10 lg:rounded-xl">
        <div ref={title} className={`mx-auto max-w-2xl lg:text-center transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <h2 className="uppercase text-base font-semibold leading-7 text-emerald-500">
            <SparklesIcon className="h-4 w-4 inline-block mr-1" aria-hidden="true" />
            Fan Centric
            <SparklesIcon className="h-4 w-4 inline-block ml-1" aria-hidden="true" />
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            We're putting fans first, <span className='text-emerald-500'>every single time</span>
          </p>
        </div>
        <div className={`mx-auto mt-8 max-w-2xl lg:mt-12 lg:max-w-4xl md:flex transition-opacity delay-150 ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          <dl className="lg:max-w-lg">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:gap-y-16">
              <div key="fan-ticketing-broken" className="relative lg:pl-8">
                <dt className="text-lg font-semibold leading-7 text-neutral-50">
                  Ticketing today is broken
                </dt>
                <dd className="mt-2 text-sm leading-6 text-neutral-400">
                  <span className="font-bold text-neutral-50">Fans have had enough.</span> Enough of the scalpers that scoop up all of the best seats within a fraction of a second. Enough of the fake tickets that resell on secondary marketplaces, with no way of knowing. Enough of the exorbitant yet hidden fees that exploit our most loyal customers...
                </dd>
                {/* <dd className="mt-4 text-sm leading-6 text-neutral-400">
                  Yet, nothing will change without real action and innovation. That&apos;s exactly why we&apos;re here, to disrupt and fix this broken system. It&apos;s about time someone did.  
                </dd> */}
              </div>
              <div key="fan-ticketing-broken" className="relative lg:pl-8">
                <dt className="text-lg font-semibold leading-7 text-neutral-50">
                Your event is important to us too
                </dt>
                <dd className="mt-2 text-sm leading-6 text-neutral-400">
                Whatever the occasion is, we know your event is special to you. That&apos;s why we&apos;re taking ticketing off your radar and making it as seamless as possible so you can focus on enjoying the experience instead.
                </dd>
              </div>
            </dl>
          </dl>
          <dl className={`
              hidden 
              pt-16 
              md:mx-8 
              md:max-w-20
              md:pt-0 
              md:block
              rounded-xl 

              w-full
              relative
              overflow-hidden
              block
              z-10

              bg-[url('https://images.unsplash.com/photo-1516450137517-162bfbeb8dba?q=80&w=387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]
              bg-cover
              bg-no-repeat
              bg-center

              before:content-['']
              before:absolute
              before:inset-0
              before:block
              before:bg-gradient-to-b
              before:from-yellow-400
              before:to-yellow-50
              before:opacity-20
              before:z-[-5]
              
              transition-opacity
              ease-in-out 
              duration-500 
              delay-300
              ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
          </dl>
        </div>
      </div>
    </div>
  )
}