import React from 'react';
import Header from '../components/LandingHeader';
import BannerSection from '../components/LandingBannerSection';
import FanSection from '../components/FanSection';
import SmartTicketSection from '../components/SmartTicketSection';
import RefundSection from '../components/RefundSection';
import LoyaltySection from '../components/LoyaltySection';
import NewsletterSection from '../components/LandingNewsletterSection'
import FooterSection from '../components/FooterSection'
import SuccessNotification from '../components/SuccessNotification'
import PrivacyPolicyModal from '../components/PrivacyPolicyModal'

function Landing() {
  return (
    <div className="bg-neutral-900">
      <Header />
      <BannerSection />
      <FanSection />
      <SmartTicketSection />
      <RefundSection />
      <LoyaltySection />
      
      <NewsletterSection />
      <FooterSection />
      <SuccessNotification />
      <PrivacyPolicyModal />
    </div>
  );
}

export default Landing;