import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTOSOpenManager } from '../../contexts/Application'

export default function TOSModal() {
  const [TOSOpen, updateTOSOpen] = useTOSOpenManager()

  return (
    <Transition.Root show={TOSOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {updateTOSOpen(false)}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-neutral-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:mx-16">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-700 text-white hover:text-neutral-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => updateTOSOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 m:ml-4 sm:mt-0 text-left">
                    <Dialog.Title as="h2" className="text-xl font-semibold leading-6 text-white">
                        Terms of Service
                    </Dialog.Title>
                    <Dialog.Description as="h4" className="italic mt-2 text-sm font-light leading-6 text-white">
                        Effective Date: December 31, 2023
                    </Dialog.Description>
                    <div className="mt-4 overflow-y-auto p-4 max-h-96 bg-neutral-600 rounded-xl">
                        <p className="text-sm text-neutral-50">
                            These Terms of Service ("Terms") govern your access to and use of the Metapass website and platform (collectively, the "Platform"). Please read these Terms carefully before accessing or using the Platform. By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to all of these Terms, you may not access or use the Platform.
                        </p>
                        <p className="mt-4 text-sm text-neutral-50">
                            <b>INTRODUCTION</b>
                            <br/><br/>
                            Metapass, LLC ("Metapass") provides a smart ticketing platform based on blockchain technology. Through the Platform, users can purchase and manage NFT tickets for events, and manage ticketing services for hosted events.
                            <br/><br/>
                            <b>PLATFORM OR PRODUCTS</b>
                            <br/><br/>
                            We reserve the right, but are not obligated, to limit the sales of our products or Platform to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Platform that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                            <br/><br/>
                            We do not warrant that the quality of any products, Platform, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Platform will be corrected.
                            <br/><br/>
                            <b>ACCESS AND USE</b>
                            <br/><br/>
                            <b>Eligibility:</b> To access and use the Platform, you must be: (a) at least 18 years old; and (b) legally able to enter into contracts.
                            <br/><br/>
                            <b>Opt-In Communication:</b> You acknowledge and agree to receive email communications from Metapass by submitting your email address to the Platform. You will have the option to unsubscribe at any time through a link provided in each correspondence. Review our Privacy Policy for more details on how submitted data is governed
                            <br/><br/>
                            <b>Beta Portal:</b> You acknowledge that you are accessing the Platform in beta testing mode. The Platform may be subject to bugs, errors, and other limitations.
                            <br/><br/>
                            Restrictions: You may not:
                        </p>
                        <ul className="list-disc text-sm text-neutral-50 ml-4">
                            <li>use the Platform for any illegal or unauthorized purpose;</li>
                            <li>violate any laws or regulations in connection with your use of the Platform;</li>
                            <li>infringe upon the intellectual property rights of Metapass or any third party;</li>
                            <li>transmit any harmful or malicious code through the Platform;</li>
                            <li>use the Platform to engage in any activity that may damage or disrupt the Platform;</li>
                            <li>use the Platform to promote illegal or illicit activities.</li>
                        </ul>
                        <br/>
                        <p className="text-sm text-neutral-50">
                            <b>METAPASS CONTENT</b>
                            <br/><br/>
                            This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                            <br/><br/>
                            USER CONTENT
                            <br/><br/>
                            You may be able to upload content to the Platform, including images, videos, and text. You are solely responsible for the content you upload.
                            <br/><br/>
                            <b>Content Restrictions:</b> You may not upload content that:
                        </p>
                        <ul className="list-disc text-sm text-neutral-50 ml-4">
                            <li>infringes upon the intellectual property rights of any third party;</li>
                            <li>is defamatory, obscene, or offensive;</li>
                            <li>promotes illegal or illicit activities;</li>
                            <li>violates any laws or regulations;</li>
                            <li>discriminates based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability.</li>
                        </ul>
                        <br/>
                        <p className="text-sm text-neutral-50">
                        <b>Ownership:</b> You retain all ownership rights to the content you upload to the Platform. However, by uploading content to the Platform, you grant Metapass a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, and distribute such content for the purpose of providing and improving the Platform.
                        <br/><br/>
                        <b>Third-Party Links:</b> Certain content and products available via our Platform may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                        <br/><br/>

                        <b>INTELLECTUAL PROPERTY</b>
                        <br/><br/>
                        <b>Metapass Content:</b> The Platform and all content on the Platform, including but not limited to text, images, videos, and logos, are the property of Metapass and are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Platform, use of the Platform, or access to the Platform or any contact on the website through which the service is provided, without express written permission by Metapass.
                        <br/><br/>
                        <b>Third-Party Content:</b> The Platform may also include content that belongs to third parties. Such third-party content is protected by the intellectual property laws of the respective owners.
                        <br/><br/>

                        <b>DISCLAIMER OF WARRANTIES</b>
                        <br/><br/>
                        THE PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. METAPASS MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. METAPASS DOES NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE.
                        <br/><br/>

                        <b>LIMITATION OF LIABILITY</b>
                        <br/><br/>
                        METAPASS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE PLATFORM, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, AND PUNITIVE DAMAGES.
                        <br/><br/>

                        <b>INDEMNIFICATION</b>
                        <br/><br/>
                        You agree to indemnify, defend, and hold harmless Metapass and its officers, directors, employees, agents, and licensors from and against all claims, losses, expenses, damages, and costs, including attorneys' fees, arising out of or relating to your use of the Platform or your violation of these Terms.
                        <br/><br/>

                        <b>GOVERNING LAW AND DISPUTE RESOLUTION</b>
                        <br/><br/>
                        These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall be conducted in the English language and shall be held in Seattle, Washington. The decision of the arbitrator shall be final and binding on both parties, and judgment on the award may be entered in any court of competent jurisdiction.
                        <br/><br/>

                        <b>ENTIRE AGREEMENT</b>
                        <br/><br/>
                        These Terms constitute the entire agreement between you and Metapass with respect to your use of the Platform. These Terms supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.
                        <br/><br/>

                        <b>AMENDMENT</b>
                        <br/><br/>
                        Metapass may amend these Terms at any time by posting the amended Terms on the Platform. The amended Terms will be effective immediately upon posting. Your continued use of the Platform after the posting of the amended Terms will constitute your acceptance of the amended Terms.
                        <br/><br/>

                        <b>SEVERABILITY</b>
                        <br/><br/>
                        If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
                        <br/><br/>

                        <b>WAIVER</b>
                        <br/><br/>
                        No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision.
                        <br/><br/>

                        <b>CHANGES TO TERMS OF SERVICE</b>
                        <br/><br/>
                        You can review the most current version of the Terms of Service at any time at this page.
                        <br/><br/>
                        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Platform following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                        <br/><br/>

                        <b>CONTACT US</b>
                        <br/><br/>
                        Any questions about these Terms can be directed to us at hello@metapass.world

                        </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-500 sm:ml-3 sm:w-auto"
                    onClick={() => updateTOSOpen(false)}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}