import { useRef, useState, useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useIsVisible } from '../../hooks';


const rewards = [
  {
    name: 'Fan Loyalty Rewards',
    id: 'rewards',
    href: '#',
    description: 'Earn points not just whenever you attend a live event, but soon with every merchandise purchase you make with an authorised outlet, and even with every track you play on partnered streaming platforms.\n\nWork your way up the tiers, and enjoy unique yet powerful perks, such as (for example):',
    features: [
      'Priority access to early bird ticket sales',
      'Guaranteed tickets for upcoming event tours',
      'Meet and greet session passes',
      'Exclusive discounts on event merchandise and F&B outlets',
    ]
  },
  {
    name: 'Take control of your experience',
    id: 'control',
    href: '#',
    description: 'Selected loyalty tiers may have the opportunity to influence concert experiences, such as voting on event venue design, artist wardrobes, and possibly even track lists!',
    features: []
  },
]

export default function CustomisableSection() {
  const title = useRef<HTMLDivElement>(null);
  const isTitleVisible = useIsVisible(title);

  useEffect(() => {
    if (isTitleVisible && !triggerTitle) {
      setTriggerTitle(true)
    }
  }, [isTitleVisible])

  const [triggerTitle, setTriggerTitle] = useState(false)


  return (
    <div className="overflow-hidden bg-neutral-900 py-64">
      <div className="flex flex-col lg:flex-row mx-auto max-w-7xl px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
        <div className={`flex order-2 lg:order-1 justify-center mt-16 lg:mt-0 transition-opacity ease-in-out duration-500 delay-300 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <img
                src="customisable.png"
                alt="Customisable"
                className="z-10 w-[400px] xl:w-[600px] aspect-auto max-w-none mx-auto"
            />
            <div className='mt-12 xl:mt-16 -ml-64 xl:-ml-80 absolute dotted-bg w-[250px] h-[250px] xl:w-[350px] xl:h-[350px]'/>
        </div>
        <div className="my-auto order-1 lg:order-2 flex flex-col items-end text-right">
          <div ref={title} className={`transition-opacity ease-in-out duration-500 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <h2 className="uppercase text-base font-semibold leading-6 text-neongreen-500">FULLY CUSTOMISABLE</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">
              <a className='text-white'>Looks and feels <br/></a>
              <a className="text-transparent bg-clip-text bg-gradient-to-r from-neongreen-500 to-teal-500">&nbsp;great for your brand</a>
            </p>
          </div>
          <div className={`max-w-xl transition-opacity ease-in-out duration-500 delay-150 ${triggerTitle ? "opacity-100" : "opacity-0"}`}>
            <p className="mt-6 text-right font-normal text-lg leading-7 text-neutral-200">
                Our tickets and interfaces are customizable with images, video, text, and color to match your creative direction.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
