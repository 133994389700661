import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'

import { safeAccess } from '../utils'

const WAITLIST_SUCCESS = 'WAITLIST_SUCCESS'
const PRIVACY_POLICY_OPEN = 'PRIVACY_POLICY_OPEN'
const TOS_OPEN = 'TOS_OPEN'

export enum UpdateTypes {
  UPDATE_WAITLIST_SUCCESS = 'UPDATE_WAITLIST_SUCCESS',
  UPDATE_PRIVACY_POLICY_OPEN = 'UPDATE_PRIVACY_POLICY_OPEN',
  UPDATE_TOS_OPEN = 'UPDATE_TOS_OPEN'
}

export type ApplicationUpdateAction = {type: UpdateTypes.UPDATE_WAITLIST_SUCCESS | UpdateTypes.UPDATE_PRIVACY_POLICY_OPEN | UpdateTypes.UPDATE_TOS_OPEN}

export interface ApplicationState {
  [WAITLIST_SUCCESS]: boolean;
  [PRIVACY_POLICY_OPEN]: boolean;
  [TOS_OPEN]: boolean;
}

export interface Payload {
  waitlistSuccess: boolean;
}

export interface Payload {
  privacyPolicyOpen: boolean;
}

export interface Payload {
  TOSOpen: boolean;
}

const ApplicationContext = createContext<[ApplicationState | undefined, {
              updateWaitlistSuccess: ((waitlistSuccess: boolean) => void) | undefined, 
              updatePrivacyPolicyOpen: ((privacyPolicyOpen: boolean) => void) | undefined, 
              updateTOSOpen: ((TOSOpen: boolean) => void) | undefined, 
            }]>([undefined, {updateWaitlistSuccess: undefined, updatePrivacyPolicyOpen: undefined, updateTOSOpen: undefined}]);

export function useApplicationContext() {
  return useContext(ApplicationContext)
}

function reducer(state: ApplicationState, { type, payload }: { type: UpdateTypes, payload: Payload | undefined}) {
  switch (type) {
    case UpdateTypes.UPDATE_WAITLIST_SUCCESS: {
      if (!payload) {
        throw Error(`No waitlist success status included in payload!`)
      }
      const { waitlistSuccess } = payload
      return {
        ...state,
        [WAITLIST_SUCCESS]: waitlistSuccess
      }
    }

    case UpdateTypes.UPDATE_PRIVACY_POLICY_OPEN: {
      if (!payload) {
        throw Error(`No privacy policy status included in payload!`)
      }
      const { privacyPolicyOpen } = payload
      return {
        ...state,
        [PRIVACY_POLICY_OPEN]: privacyPolicyOpen
      }
    }

    case UpdateTypes.UPDATE_TOS_OPEN: {
      if (!payload) {
        throw Error(`No TOS status included in payload!`)
      }
      const { TOSOpen } = payload
      return {
        ...state,
        [TOS_OPEN]: TOSOpen
      }
    }

    default: {
      throw Error(`Unexpected action type in ApplicationContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }: {children: any}) {
  const [state, dispatch] = useReducer(reducer, {
    [WAITLIST_SUCCESS]: false,
    [PRIVACY_POLICY_OPEN]: false,
    [TOS_OPEN]: false
  })

  const updateWaitlistSuccess = useCallback((waitlistSuccess: boolean) => {
    dispatch({ type: UpdateTypes.UPDATE_WAITLIST_SUCCESS, payload: { waitlistSuccess } as Payload })
  }, [])

  const updatePrivacyPolicyOpen = useCallback((privacyPolicyOpen: boolean) => {
    dispatch({ type: UpdateTypes.UPDATE_PRIVACY_POLICY_OPEN, payload: { privacyPolicyOpen } as Payload })
  }, [])

  const updateTOSOpen = useCallback((TOSOpen: boolean) => {
    dispatch({ type: UpdateTypes.UPDATE_TOS_OPEN, payload: { TOSOpen } as Payload })
  }, [])

  return (
    <ApplicationContext.Provider
      value={useMemo(() => [state, { updateWaitlistSuccess, updatePrivacyPolicyOpen, updateTOSOpen }], [
        state,
        updateWaitlistSuccess,
        updatePrivacyPolicyOpen,
        updateTOSOpen
      ])}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

export function useWaitlistSuccessManager() {

  const [state, {updateWaitlistSuccess}] = useApplicationContext();

  const waitlistSuccess = safeAccess(state, [WAITLIST_SUCCESS]) as boolean;

  return [waitlistSuccess || false, updateWaitlistSuccess] as [boolean, ((waitlistSuccess: boolean) => void)]
  
}


export function usePrivacyPolicyOpenManager() {

  const [state, {updatePrivacyPolicyOpen}] = useApplicationContext();

  const privacyPolicyOpen = safeAccess(state, [PRIVACY_POLICY_OPEN]) as boolean;

  return [privacyPolicyOpen || false, updatePrivacyPolicyOpen] as [boolean, ((privacyPolicyOpen: boolean) => void)]
  
}

export function useTOSOpenManager() {

  const [state, {updateTOSOpen}] = useApplicationContext();

  const TOSOpen = safeAccess(state, [TOS_OPEN]) as boolean;

  return [TOSOpen || false, updateTOSOpen] as [boolean, ((TOSOpen: boolean) => void)]
  
}