import { useRef, useState, useEffect } from 'react'

export default function DashboardSection() {
  // const title = useRef<HTMLDivElement>(null);
  // const isTitleVisible = useIsVisible(title);

  // useEffect(() => {
  //   if (isTitleVisible && !triggerTitle) {
  //     setTriggerTitle(true)
  //   }
  // }, [isTitleVisible])

  // const [triggerTitle, setTriggerTitle] = useState(false)


  return (
    <div className="z-50">
      <div className="lg:-mt-64 relative py-12 mx-auto lg:max-w-6xl px-20 lg:z-10 lg:rounded-xl">
        <img src="metapass_dashboard.png" alt="metapass_dashboard" />
      </div>
    </div>
  )
}