import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { usePrivacyPolicyOpenManager } from '../../contexts/Application'

export default function PrivacyPolicyModal() {
  const [privacyPolicyOpen, updatePrivacyPolicyOpen] = usePrivacyPolicyOpenManager()

  return (
    <Transition.Root show={privacyPolicyOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {updatePrivacyPolicyOpen(false)}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-neutral-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:mx-16">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-700 text-white hover:text-neutral-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => updatePrivacyPolicyOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 m:ml-4 sm:mt-0 text-left">
                    <Dialog.Title as="h2" className="text-xl font-semibold leading-6 text-white">
                        Privacy Policy
                    </Dialog.Title>
                    <Dialog.Description as="h4" className="italic mt-2 text-sm font-light leading-6 text-white">
                        Effective Date: December 31, 2023
                    </Dialog.Description>
                    <div className="mt-4 overflow-y-auto p-4 max-h-96 bg-neutral-600 rounded-xl">
                        <p className="text-sm text-neutral-50">
                            This privacy policy (the “Policy”) describes how we collect, use, protect and share your personal information when you visit our website www.metapass.world (the “Platform”).
                        </p>
                        <p className="mt-4 text-sm text-neutral-50">
                            <b>INFORMATION WE COLLECT</b>
                            <br/><br/>
                            We collect certain information about you when you visit the Platform, including:
                        </p>
                        <ul className="list-disc text-sm text-neutral-50 ml-4">
                            <li>Your IP address</li>
                            <li>Your browser type and operating system</li>
                            <li>The pages you visit on the Platform</li>
                            <li>The date and time you access the Platform</li>
                            <li>Any other information you choose to provide us through our contact forms or email addresses.</li>
                            <li>We may also use cookies or similar technologies to collect information about your browsing activities.</li>
                        </ul>
                        <br/>
                        <p className="text-sm text-neutral-50">


                            <b>HOW WE USE YOUR INFORMATION</b>
                            <br/><br/>
                            We use the information we collect to:
                            Improve and personalize your experience on the Platform
                            Respond to your inquiries and requests
                            Send you marketing and promotional materials
                            Monitor and analyze trends, usage and activities on the Platform
                            Comply with applicable laws and regulations.

                            <br/><br/>
                            <b>HOW WE PROTECT YOUR INFORMATION</b>
                            <br/><br/>
                            We take appropriate measures to protect your personal information from unauthorized access, use, alteration or disclosure. We use industry-standard security technologies and procedures to safeguard your information.


                            <br/><br/>
                            <b>SHARING YOUR INFORMATION</b>
                            <br/><br/>
                            We may share your personal information with third-party service providers who assist us with our operations, such as website hosting, data analytics, and marketing. We may also share your information with government authorities or law enforcement officials if required by law.
                            <br/><br/>


                            <b>YOUR RIGHTS</b>
                            <br/><br/>
                            You have the right to access, update or delete your personal information at any time by contacting us using the contact information provided below.
                            <br/><br/>


                            <b>CHANGES TO THIS POLICY</b>
                            <br/><br/>
                            We may update this Policy from time to time. We will notify you of any changes by posting the new Policy on the Platform. You should review this Policy periodically for any changes.
                            <br/><br/>


                            <b>CONTACT US</b>
                            <br/><br/>
                            If you have any questions about this Policy, please contact us at hello@metapass.world
                        </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-neutral-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-500 sm:ml-3 sm:w-auto"
                    onClick={() => updatePrivacyPolicyOpen(false)}
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}