import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { usePrivacyPolicyOpenManager, useTOSOpenManager } from '../../contexts/Application'


export default function FooterSection() {
  const [, updatePrivacyPolicyOpen] = usePrivacyPolicyOpenManager()
  const [, updateTOSOpen] = useTOSOpenManager()

  return (
    <div className="bg-neutral-950">
      <div className="mx-auto max-w-6xl px-16 lg:px-40 text-neutral-400">
        <div className="flex flex-col gap-5 justify-between md:gap-0 md:flex-row py-8">
          <div className="flex flex-row gap-5 ">
            <img
              src="metapass_min_gray.svg"
              alt="Metapass Logo"
              className="w-6 h-6"
            />
            <a href="https://twitter.com/metapass_world" className='block' target="_blank">
              <img
                src="twitter_gray.svg"
                alt="Twitter Logo"
                className="w-6 h-6"
              />
            </a>
          </div>
          <div>
            <p className="mx-auto max-w-2xl text-xs leading-6 font-normal text-left md:text-center">
              Copyright © 2024 Metapass, LLC. All rights reserved.
            </p>
          </div>
          <div className="flex flex-col items-start text-left md:items-end md:text-right">
            {/* <h6 className="uppercase font-semibold text-sm leading-5 pb-4">Legal</h6> */}
            <div className="flex flex-row gap-4 text-xs leading-6">
              {/* <a href="">Claim</a> */}
              <a href="#" className="underline" onClick={(e) => {
                    e.preventDefault()
                    updatePrivacyPolicyOpen(true)
                  }}>Privacy</a>
              <a href="#" className="underline" onClick={(e) => {
                    e.preventDefault()
                    updateTOSOpen(true)
                  }}>Terms</a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
